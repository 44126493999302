import './App.css';

import useLocalStorageState from "use-local-storage-state";
var Barcode = require('react-barcode');

const Sticker = ({ num }) => {

  const [model, setModel] = useLocalStorageState(`model${num}`, "");
  const [number, setNumber] = useLocalStorageState(`number${num}`, "");

  return (
    <div>
      <div className='model'><input
        value={model}
        onChange={(event) => setModel(event.target.value)}
        size="20"
      /></div>
      <div className='barcode'>
        {number ? <Barcode value={number} format="code128" width="1" displayValue={false} marginTop={1} marginBottom={1} /> : null}
      </div>
      <div className='number'>
        <input
          value={number}
          onChange={(event) => setNumber(event.target.value)}
          size="20"
        />
      </div>
    </div>)
}

function App() {
  return (
    <div class="content">
      <table aria-colspan={0} className='layout'>
        <tr className='top-row'><td></td></tr>
        {Array(13).fill(0).map((_, r) => <tr className='row'>
          {Array(5).fill(0).map((_, d) => <td>
            <Sticker num={r * 5 + d} />
          </td>)}
        </tr>)}
      </table>
    </div>
  );
}

export default App;
